export const nfse_writer_decorators = {
    nome: "NFSE_W",

    label_step_determinacao: "Determinação",
    label_step_comparacao: "Simulação",
    label_step_aprovacao: "Aprovação",
    label_step_escrituracao: "Escrituração",

    label_nome_documento: "Serviço",
    label_codigo_documento: "Código do serviço",
    label_descricao_documento: "Descrição do serviço",
    label_valor_documento: "Valor total do serviço (R$)",

    label_item_documento: "Itens do serviço",

    label_prestador: "Prestador",
    label_tomador: "Tomador",
    label_min_tomador: "tomador",
    label_min_prestador: "prestador",
    label_cnpj_prestador: "CNPJ do prestador",
    label_cnpj_tomador: "CNPJ do tomador"

};
